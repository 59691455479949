import React from "react";

import Products from "../products";
import ProductCard from "../../components/ProductCards/ProductCards";
import SEO from "../../components/SEO/Seo";
import espumantes from "../../data/espumantes";

const Espumantes = () => {
  return (
    <>
      <SEO
        title="Blue Sky SA - Botellas de Vidrio. Espumantes y Champagne"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio , Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  - Botellas de vidrio Blue Sky SA - Botellas de vidrio . Espumantes. Champagne, champañeras o cava. Alta, baja, con picada, sin picada. 750 ml. Color verde, ambar, marrón, blanco y verde oscuro. Boca corcho y corona. Mendoza - Argentina."
        description=" Encuentra botellas Espumantes y Champagne  en Blue Sky SA.  -Blue Sky SA - Botellas de vidrio . Espumantes. Champagne, champañeras o cava. Alta, baja, con picada, sin picada. 750 ml. Color verde, ambar, marrón, blanco y verde oscuro. Boca corcho y corona. Mendoza - Argentina."
      />
      <Products subtitle="espumantes">
        <ProductCard data={espumantes} />
      </Products>
    </>
  );
};

export default Espumantes;
