import {
  Espumante1,
  Espumante2,
  Espumante3,
  Espumante4,
  Espumante5,
  Espumante6,
  Espumante7,
  Espumante8,
  Espumante9,
  Espumante10,
  Espumante12,
  Espumante13,
  Espumante14,
  Espumante15,
  Espumante16,
  Espumante17,
  Espumante18,
  Espumante19,
  Espumante1_3,
  Espumante2_3,
  Espumante3_3,
  Espumante4_3,
  Espumante5_3,
  Espumante6_3,
  Espumante7_3,
  Espumante8_3,
  Espumante9_3,
  Espumante10_3,
  Espumante12_3,
  Espumante13_3,
  Espumante14_3,
  Espumante15_3,
  Espumante16_3,
  Espumante17_3,
  Espumante18_3,
  Espumante19_3
} from "../images/productos/espumantes";

import {
  PDF1,
  PDF2,
  PDF3,
  PDF4,
  PDF5,
  PDF6,
  PDF7,
  PDF8,
  PDF9,
  PDF10,
  PDF12,
  PDF_CHAMPAGNEBALTHAZAR,
  PDF_CHAMPAGNEJEROBOAM,
  PDF_CHAMPAGNEMATHUSALEM,
  PDF_CHAMPAGNENABUCODONOSOR,
  PDF_CHAMPAGNESALMANAZARNEW,
  PDF_CHAMPAGNESALOMON,
  PDF_ANDES
} from "../../static/10-espumantes/index";
const espumantes = [
  {
    size: 375,
    srcModal: Espumante1_3,
    src: Espumante1,
    pdfDownload: PDF1,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },

  {
    size: 375,
    srcModal: Espumante4_3,
    src: Espumante4,
    pdfDownload: PDF4,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },

  {
    size: 375,
    srcModal: Espumante7_3,
    src: Espumante7,
    pdfDownload: PDF7,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 375,
    srcModal: Espumante8_3,
    src: Espumante8,
    pdfDownload: PDF8,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 375,
    srcModal: Espumante9_3,
    src: Espumante9,
    pdfDownload: PDF9,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 375,
    srcModal: Espumante12_3,
    src: Espumante12,
    pdfDownload: PDF12,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 375,
    srcModal: Espumante17_3,
    src: Espumante17,
    pdfDownload: PDF_CHAMPAGNEJEROBOAM,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 375,
    srcModal: Espumante14_3,
    src: Espumante14,
    pdfDownload: PDF_CHAMPAGNEMATHUSALEM,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },

  {
    size: 375,
    srcModal: Espumante16_3,
    src: Espumante16,
    pdfDownload: PDF_CHAMPAGNESALMANAZARNEW,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },

  {
    size: 375,
    srcModal: Espumante18_3,
    src: Espumante18,
    pdfDownload: PDF_CHAMPAGNEBALTHAZAR,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 375,
    srcModal: Espumante15_3,
    src: Espumante15,
    pdfDownload: PDF_CHAMPAGNENABUCODONOSOR,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 375,
    srcModal: Espumante13_3,
    src: Espumante13,
    pdfDownload: PDF_CHAMPAGNESALOMON,
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 375,
    srcModal: Espumante19_3,
    src: Espumante19,
    pdfDownload: PDF_ANDES,
    name: "andes",
    color: {
      white: true,
      green: true,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
];
export default espumantes;
